<template>
  <section
    class="hero-carousel"
    :class="!herocarouseldata?.heroWidth ? 'hero-full' : 'container'"
  >
    <carousel
      class="hero-carousel__wrap"
      :items="1"
      :loop="true"
      :nav="false"
      :dots="true"
      :autoplay="true"
      :autoplay-speed="true"
      :autoplay-timeout="5000"
    >
      <div
        v-for="(
          { carouselImageUrl, carouselText, carouselImageAltTag, leftFocalPoint, topFocalPoint },
          index
        ) in herocarouseldata.carouselItems"
        :key="index"
        class="hero-carousel__slide"
      >
        <div
          class="hero-overlay"
          :style="{ opacity: opacityValue + '%' }"
        />
        <div
          v-if="carouselImageUrl"
          class="hero-carousel__image is-background"
          :style="{
            backgroundImage: 'url(\'' + carouselImageUrl + '\')',
            backgroundPosition: '' + leftFocalPoint + ' ' + topFocalPoint + ''
          }"
          :alt="carouselImageAltTag"
        />
        <h1
          v-if="carouselText"
          class="hero-carousel__title"
          :class="!herocarouseldata.heroWidth ? 'container' : ''"
        >
          {{ carouselText }}
        </h1>
      </div>
    </carousel>
  </section>
</template>

<script>
import carousel from 'vue-owl-carousel';
export default {
  name: 'HeroCarousel',
  components: { carousel },
  props: ['herocarouseldata'],
  data() {
    return {
      opacityValue: 50
    };
  },
  mounted() {
    this.opacityValue =
      this.herocarouseldata?.opacityHeroValue !== ''
        ? this.herocarouseldata?.opacityHeroValue
        : this.opacityValue;
  }
};
</script>
