import 'url-polyfill';
import Vue from 'vue';
import ValidateModal from '../vue-components/modals/validate-modal.vue';

function validateModal() {
  new Vue({
    el: '#validate-modal',
    components: {
      'validate-modal': ValidateModal
    },
    data: function () {
      return window.Cms.validateModal;
    }
  });
}

export default validateModal;
