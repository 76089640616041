<template>
  <transition name="fade">
    <div
      class="dates-prices-item"
      :class="localappdata.isLoadingMore ? 'loading' : ''"
    >
      <div
        class="dates-prices-header is-flex-tablet columns has-background-light is-vcentered"
        :class="{ 'credit-active': offerIsTrue }"
      >
        <div class="dates-prices-header__dates column is-one-third">
          <div class="start-end-header is-size-7">
            <span class="start-date-header">{{ model.labels.startDateText }}</span>
            <span>&nbsp;/&nbsp;</span>
            <span class="end-date-header">{{ model.labels.endDateText }}</span>
          </div>
          <div class="start-end-dates">
            <span class="start-date">{{ row.startDate }}</span>
            <span
              class="arrow"
              aria-hidden="true"
            ><i class="fa fa-arrow-right is-size-7 has-text-grey-dark" /></span>
            <span class="end-date">{{ row.endDate }}</span>
          </div>
        </div>
        <div class="is-flex-tablet column is-two-thirds is-flex dates-prices-header__info">
          <div class="column is-one-third-tablet">
            <div
              v-if="raqmodel.loginForPriceModel.isEnabled && !raqmodel.isLoggedIn"
              class="is-flex logged-in-container dates-prices-login-for-price-call-to-action is-full"
            >
              <img
                class="login-brand-logo logo-image"
                :src="raqmodel.loginForPriceModel.logoUrl"
                alt="logo"
              >&nbsp;
              <a
                :href="raqmodel.loginForPriceModel.loginUrl"
                class="login-link customer-price-link"
                @click="dataLayerLoginForPriceClicked()"
              >
                {{ raqmodel.loginForPriceModel.text }}
              </a>
            </div>
            <div
              v-if="raqmodel.loginForPriceModel.isEnabled && raqmodel.isLoggedIn"
              class="logged-in-container is-flex is-full"
            >
              <div
                class="flex-column logged-in-box-container"
                :class="{ 'has-offer': offerIsTrue }"
                :data-offer-text="offerIsTrue ? model.labels.shipBoardCreditValue : null"
              >
                <div class="is-flex logged-in-box">
                  <img
                    class="login-brand-logo logo-image"
                    :src="raqmodel.loginForPriceModel.logoUrl"
                    alt="logo"
                  >&nbsp;
                  <span class="is-size-7"><em v-html="model.labels.exclusivePriceLabel" /></span>
                </div>
              </div>
            </div>
          </div>
          <div class="airfare-container column is-one-third-tablet is-full is-flex">
            <div
              class="airfare__icon"
              :class="!row.includesAirFare ? 'strikethrough' : ''"
            >
              <i class="fa fa-plane fa-sm" />
            </div>
            &nbsp;
            <div
              v-if="row.includesAirFare"
              class="airfare__text is-size-7"
              v-html="
                model.labels.includesAirfareText
                  ? model.labels.includesAirfareText
                  : 'Flights Included'
              "
            />
            <div
              v-if="!row.includesAirFare"
              class="airfare__text is-size-7"
              v-html="
                model.labels.excludesAirfareText
                  ? model.labels.excludesAirfareText
                  : 'Flights not Included'
              "
            />
          </div>
          <div class="column is-one-third is-hidden-mobile">
            <quote-raq-button
              v-if="!noDealsAvailable"
              :tour="raqmodel.tour"
              :labels="raqmodel.labels"
              :tourselecteddate="row.startDate"
            />
            <a
              v-if="noDealsAvailable"
              :href="telNumber"
            >
              <button
                :title="telNumber"
                class="call-now-button button is-primary is-pulled-right cta"
              >
                Call Now
              </button>
            </a>
          </div>
        </div>
      </div>
      <div class="deals-container is-flex-tablet columns has-background-white">
        <div class="deals-details-container column is-one-third">
          <div
            v-if="row.currentDeals.length"
            class="deal-details column is-two-thirds is-relative"
            @click="updateCurrentModalContent($event)"
          >
            <div v-if="row.currentDeals.length">
              <div class="current-deals-header is-size-7 has-text-weight-bold">
                Current Deals:
              </div>
              <div
                v-for="deal in row.currentDeals"
                :key="deal.index"
                class="current-deals-info is-size-7"
              >
                <div
                  class="deal-details__title has-text-weight-bold"
                  :title="deal.title"
                >
                  <span v-if="deal.title.toString().includes('Flights Included')">
                    <i class="fa fa-plane fa-rotate-270" />
                  </span>
                  {{ deal.title }}
                </div>
              </div>
            </div>
            <div
              v-if="row.themes.length"
              class="current-deals-info is-size-7"
            >
              <div
                class="deal-details__title theme-title has-text-weight-bold text-underline"
                :class="{ 'border-top': row.currentDeals.length }"
              >
                Cruise Themes
              </div>
            </div>
            <div
              v-for="theme in row.themes"
              :key="theme.index"
              class="current-deals-info is-size-7"
            >
              <div
                class="deal-details__title has-text-weight-bold"
                :title="theme.title"
              >
                {{ theme.themeName }}
              </div>
            </div>
            <div class="hidden modal-current-deal-container">
              <div v-if="row.currentDeals.length">
                <div
                  v-for="(deal, index) in row.currentDeals"
                  :key="index"
                  class="modal-current-deal"
                  :class="{
                    'margin-bottom':
                      index < row.currentDeals.length - 1 ||
                      (row.currentDeals.length == 1 && row.themes.length)
                  }"
                >
                  <div class="current-deal-header has-text-weight-bold">
                    {{ deal.title }}
                  </div>
                  <div class="current-deal-subtitle is-italic">
                    {{ deal.subTitle }}
                  </div>
                  <div class="current-deal-description">
                    {{ deal.description }}
                  </div>
                </div>
              </div>
              <div v-if="row.themes.length">
                <div
                  class="has-text-weight-bold is-size-6 text-underline theme-title"
                  :class="{ 'border-top': row.currentDeals.length }"
                >
                  Cruise Themes
                </div>
                <div
                  v-for="(theme, index) in row.themes"
                  :key="index"
                  class="modal-current-deal"
                  :class="{ 'margin-bottom': index < row.themes.length - 1 }"
                >
                  <div class="current-deal-header has-text-weight-bold">
                    {{ theme.themeName }}
                  </div>
                  <div
                    class="current-deal-description"
                    v-html="theme.themeRichDescription"
                  />
                </div>
              </div>
            </div>
            <div class="current-deal-expand is-size-7">
              <span class="">Click to see more</span>
              <span>
                <i class="fa fa-search-plus" />
              </span>
            </div>
          </div>
        </div>
        <div class="prices-container column is-flex-tablet">
          <div
            v-for="category in row.categories"
            :key="category.index"
            class="category-container is-flex is-size-7"
            :class="!category.isAvailable ? 'disabled' : ''"
          >
            <span class="category-name is-uppercase has-text-weight-bold">{{ category.name }}</span>
            <span
              v-if="regionalSettings.displayOriginalAdultPrice"
              class="category-price strikethrough"
              :class="!category.originalPrice ? 'is-color-transparent' : ''"
              v-text="category.originalPrice ? category.originalPrice : 'empty'"
            />
            <span
              class="category-price is-size-6 has-text-weight-bold"
              v-text="category.price"
            />
          </div>
        </div>
        <div class="column is-hidden-tablet mobile-quote-button is-flex">
          <quote-raq-button
            v-if="!noDealsAvailable"
            :tour="raqmodel.tour"
            :labels="raqmodel.labels"
            :tourselecteddate="row.startDate"
          />
          <a v-if="noDealsAvailable">
            <button class="call-now-button button is-primary is-pulled-right cta">Call Now</button>
          </a>
        </div>
      </div>
      <div class="trip-details columns has-background-white is-vcentered">
        <div class="cruise-details column is-flex-tablet is-size-7 is-one-third-desktop">
          <div class="cruise-details__header has-text-weight-bold">
            Cruise Itinerary:
          </div>
          <div class="cruise-details__description">
            {{
              row.cruiseItinerary
                .replace(/\(.*?\)/g, '')
                .replace(/\bdepart\b/gi, '')
                .replace(/\bembark\b/gi, '')
            }}
          </div>
        </div>
        <div class="ship-details column is-flex-tablet is-size-7 is-one-third-desktop">
          <div class="ship-details__header has-text-weight-bold">
            <i class="fa fa-ship" />
            Ship(s):
          </div>
          <div
            v-for="(ship, index) in row.shipNames"
            :key="ship.index"
            class="ship-details__description"
          >
            <span> {{ ship }}<span v-if="index < row.shipNames.length - 1">,&nbsp;</span> </span>
          </div>
        </div>
        <div
          v-if="noDealsAvailable"
          class="no-deals-info column is-flex-tablet is-size-7 is-one-third-desktop has-text-weight-bold"
        >
          This departure has limited availability. We may still be able to get you on this date, so
          please feel free to get in touch.
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import QuoteRaqButton from '../modals/raq-modal-button.vue';
export default {
  components: {
    'quote-raq-button': QuoteRaqButton
  },
  props: ['model', 'row', 'raqmodel', 'telNumber', 'localappdata', 'regionalSettings'],
  data() {
    return {
      activeItemsCount: [],
      userActions: this.localappdata.userActions
    };
  },
  computed: {
    noDealsAvailable: function () {
      let dealsAvailable = [];
      for (let i = 0; i < this.row.categories.length; i++) {
        dealsAvailable.push(this.row.categories[i].isAvailable);
      }
      if (dealsAvailable.toString().includes('true')) {
        return false;
      } else {
        return true;
      }
    },
    offerIsTrue() {
      if (
        this.raqmodel.loginForPriceModel.isLoggedIn &&
        this.raqmodel.loginForPriceModel.isExecutiveMember &&
        this.raqmodel.loginForPriceModel.isSite.toLowerCase().includes('costco') &&
        this.model.labels.isUniworld &&
        this.model.labels.shipBoardCreditValue
      ) {
        return true;
      }
    }
  },
  methods: {
    updateCurrentModalContent(event) {
      let modalContent = event.currentTarget.querySelector(
        '.modal-current-deal-container'
      ).innerHTML;
      this.$emit('update-current-modal-content', modalContent);
    },
    dataLayerLoginForPriceClicked() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: 'Login Clicked From Trip Page' });
    }
  }
};
</script>
