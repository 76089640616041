import Vue from 'vue';
import SecundaryMenu from '../vue-components/generic/secondary-menu.vue';
function secundaryMenuView() {
  new Vue({
    el: '#secundary-menu',
    components: {
      'secundary-menu': SecundaryMenu
    },
    data() {
      return {
        datamenu: window.Cms.subNavModel
      };
    }
  });
}
export default secundaryMenuView;
