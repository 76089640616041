<template>
  <section
    id="overview-section"
    class="overview section overview-full-width"
  >
    <div class="container">
      <div class="columns">
        <div class="column is-9 lead">
          <div class="level is-marginless">
            <div class="level-left">
              <img
                v-if="generatedmodel?.tddOverviewIconUrl !== ''"
                class="level-item"
                :src="generatedmodel?.tddOverviewIconUrl"
                :alt="
                  generatedmodel?.tddOverviewIconAltTag ? generatedmodel?.tddOverviewIconAltTag : ''
                "
              >
              <h2 class="level-item">
                {{ dictionarymodel?.overview }}
              </h2>
            </div>
          </div>
          <div v-if="!overviewmodel.description !== '' || !overviewmodel.description.length">
            <div
              v-for="(desc, index) in overviewmodel.description"
              v-if="raqmodel?.tour.isV4Tour"
              :key="index"
            >
              <p>{{ desc }}</p>
            </div>
            <div
              v-for="(desc, index) in overviewmodel.description"
              v-if="raqmodel?.tour.isC1Tour"
              :key="index"
              v-html="desc"
            />
          </div>
          <div
            v-if="overviewmodel?.locationsVisited !== ''"
            class="details"
          >
            <p class="title">
              {{ dictionarymodel?.locationsVisited }}
            </p>
            <p>{{ overviewmodel?.locationsVisited }}</p>
          </div>
        </div>
        <div class="column is-3 details">
          <div v-if="overviewmodel?.countriesVisited !== ''">
            <p class="label">
              {{ dictionarymodel?.countriesVisited }}
            </p>
            <p>{{ overviewmodel?.countriesVisited }}</p>
          </div>

          <div v-if="overviewmodel?.travelStyle !== ''">
            <p class="label">
              {{ dictionarymodel?.travelStyle }}
            </p>
            <p>{{ overviewmodel?.travelStyle }}</p>
          </div>

          <div v-if="!overviewmodel.travelType === ''">
            <p class="label">
              {{ dictionarymodel.travelType }}
            </p>
            <p>{{ overviewmodel.travelType }}</p>
          </div>

          <div v-if="overviewmodel.nights > 0">
            <p class="label">
              {{ dictionarymodel?.accommodations }}
            </p>
            <p>
              {{ overviewmodel?.nights }}
              {{ dictionarymodel?.nightsIncluded }}
            </p>
          </div>

          <div v-if="overviewmodel?.ageRestriction !== ''">
            <p class="label">
              {{ dictionarymodel?.ageRequirements }}
            </p>
            <p>{{ overviewmodel?.ageRestriction }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'OverviewSection',
  props: ['overviewmodel', 'generatedmodel', 'dictionarymodel', 'raqmodel', 'filtervalues'],
  data() {
    return {};
  },
  methods: {
    getDepartureDate(dateValue) {
      let dateArray = dateValue.split('/');
      const date = new Date();
      date.setMonth(dateArray[0] - 1);

      let monthValue = date.toLocaleString('en-US', {
        month: 'long'
      });

      return monthValue + ' ' + dateArray[1];
    }
  }
};
</script>
