import Vue from 'vue';
import HeroEmbeddedSearch from '../../vue-components/heros/hero-embeddedSearch.vue';

function heroEmbeddedSearchView() {
  new Vue({
    el: '#hero-embeddedSearch',
    components: {
      'hero-embedded-search': HeroEmbeddedSearch
    },
    data() {
      return {
        herodata: window.Cms.heroModel
      };
    }
  });
}
export default heroEmbeddedSearchView;
