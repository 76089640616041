<template>
  <div
    data-type="promotion"
    :data-name="row.title"
    class="ttc-specials-items specialslistings__listing"
  >
    <div class="columns">
      <div class="column specialslistings__listing__image is-3-tablet">
        <a
          v-if="row.imageUrl"
          :href="row.linkUrl"
          class="specialslistings__listing__image__link background-image-center"
          :alt="row.title"
          :aria-label="row.title"
        >
          <img
            class="is-background"
            :src="row.imageUrl"
            :alt="row.imageAltText ? row.imageAltText : 'specials-listing'"
          >
        </a>
        <div
          v-if="!ongoing && row.brandLogoUrl"
          class="searchlisting-brand-image-ss"
        >
          <img
            :src="row.brandLogoUrl"
            :alt="row.brandLogoAltText"
            class="image deal-brand-logo-ss"
          >
        </div>
        <div v-show="!row.imageUrl">
          <h2
            v-if="row.imageTitle"
            v-text="row.imageTitle"
          />
          <p
            v-if="row.imageSubtitle"
            v-text="row.imageSubtitle"
          />
        </div>
      </div>
      <div class="column specialslistings__listing__desc is-6-desktop is-7-widescreen">
        <h3
          v-show="row.title"
          v-text="row.title"
        />
        <div
          v-if="row.dealBrands"
          class="level"
        >
          <div class="level-left">
            <p
              class="level-item category"
              v-text="labels.labelBrands"
            />
            <p
              class="level-item"
              v-text="row.dealBrands"
            />
          </div>
        </div>
        <div
          v-if="row.dealRegion"
          class="level deal-region"
        >
          <div class="level-left">
            <p
              class="level-item category"
              v-text="labels.labelRegion"
            />
            <p
              class="level-item"
              v-text="row.dealRegion"
            />
          </div>
        </div>
        <div
          v-if="row.bookByDate"
          class="level deal-bookBy"
        >
          <div class="level-left">
            <p
              class="level-item category"
              v-text="labels.labelBookBy"
            />
            <p
              class="level-item"
              v-text="row.bookByDate"
            />
          </div>
        </div>
        <div class="level specialslistings__description_text">
          <div class="columns">
            <div class="column column-category deal-description-label">
              <p
                class="category"
                v-text="labels.labelDescription"
              />
            </div>
            <div class="column column-description">
              <div
                class="deal-description"
                v-html="row.description"
              />
              <div class="deal-terms">
                <a
                  :aria-label="row.title"
                  @click.prevent="toggleModal(row.terms, row.title)"
                  v-text="labels.labelTerms"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column specialslistings__listing__price column is-3-desktop is-2-widescreen">
        <img
          v-if="!ongoing && row.brandLogoUrl"
          :src="row.brandLogoUrl"
          :alt="row.brandLogoAltText"
          class="image deal-brand-logo-ls"
        >
        <a
          v-if="row.detailsLinkUrl"
          class="button is-subtle"
          :href="row.detailsLinkUrl"
          :target="row.target"
          :aria-label="row.title"
          v-text="row.detailsLinkName"
        />
        <a
          v-if="row.linkUrl"
          :class="{ 'is-single-deal-btn': !row.detailsLinkUrl }"
          :target="row.target"
          :aria-label="row.title"
          class="button is-primary"
          :href="row.linkUrl"
          v-text="row.linkName"
        />
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from '../../vue-functions/event-bus';
export default {
  props: ['row', 'labels', 'ongoing'],
  methods: {
    toggleModal(terms, title) {
      EventBus.$emit('TOGGLE_TERMS_MODAL', terms, title);
    }
  }
};
</script>
