<template>
  <div class="single-month-picker">
    <div class="calendar-component-wrapper">
      <div
        class="calendar-component-inner"
        :style="computeContainerStyle"
      >
        <div
          v-for="{ year, months } in yearmonthsData"
          :key="year"
          class="calendar-component calendar-component-year"
          :style="styleWidth"
        >
          <div class="calendar-header">
            <button
              class="prev-year nav-year"
              aria-label="prev-year"
              :class="{ disabled: slidePrevDisabled }"
              @click="slidePrevYear"
            >
              <span class="icon is-small">
                <i
                  class="fas fa-angle-left"
                  aria-hidden="true"
                />
              </span>
            </button>
            <span class="current-year">{{ year }}</span>
            <button
              class="next-year nav-year"
              aria-label="next-year"
              :class="{ disabled: slideNextDisabled }"
              @click="slideNextYear"
            >
              <span class="icon is-small">
                <i
                  class="fas fa-angle-right"
                  aria-hidden="true"
                />
              </span>
            </button>
          </div>
          <div class="calendar-months">
            <button
              v-for="(month, monthIndex) in allMonths"
              :key="month + monthIndex"
              class="calendar-month-btn"
              :class="{
                disabled: !months.includes((monthIndex + 1).toString().padStart(2, '0')),
                'is-active': selected
                  ? isActive(selected, (monthIndex + 1).toString().padStart(2, '0'), year)
                  : ''
              }"
              :disabled="!months.includes((monthIndex + 1).toString().padStart(2, '0'))"
              :value="(monthIndex + 1).toString().padStart(2, '0') + '/' + year"
              :aria-label="month"
              @click="selectedMonths"
            >
              {{ month }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['yearmonths', 'selected'],
  data: function () {
    return {
      yearsToRender: 3,
      allMonths: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ],
      datesSelected: [],
      visibleYear: 1,
      slidePosition: 0,
      slideNextDisabled: false,
      slidePrevDisabled: true,
      buttonActiveClass: 'is-active',
      yearmonthsData: []
    };
  },
  computed: {
    styleWidth() {
      return 'width:' + 100 / this.yearsToRender + '%';
    },
    computeContainerStyle: function () {
      return (
        'width:' +
        this.yearsToRender * 100 +
        '%' +
        ';' +
        'margin-left:' +
        '-' +
        this.slidePosition +
        '%' +
        ';'
      );
    }
  },
  mounted() {
    this.yearsToRender = this.updatesYearMonths(this.yearmonths).length;
    this.yearmonthsData = this.updatesYearMonths(this.yearmonths);
    this.slideNextDisabled = this.yearsToRender > 1 ? false : true;
  },

  methods: {
    isActive(selectedDate, month, year) {
      return selectedDate.split('/')[0] === month && year === selectedDate.split('/')[1];
    },
    slideNextYear() {
      if (this.slideNextDisabled) {
        return;
      }
      let slideValue = 100;
      this.visibleYear++;
      this.slidePosition += slideValue;
      this.slidePrevDisabled = false;
      if (this.visibleYear === this.yearsToRender) {
        this.slideNextDisabled = true;
      }
    },
    slidePrevYear() {
      if (this.slidePrevDisabled) {
        return;
      }
      let slideValue = 100;
      this.visibleYear--;
      this.slidePosition -= slideValue;
      this.slideNextDisabled = false;
      if (this.visibleYear === 1) {
        this.slidePrevDisabled = true;
      }
    },
    selectedMonths(event) {
      let buttonValue = '';
      document.querySelectorAll('.single-month-picker .calendar-month-btn').forEach((item) => {
        if (event.target !== item) {
          item.classList.remove(this.buttonActiveClass);
        }
      });
      event.target.classList.toggle(this.buttonActiveClass);
      if (event.target.classList.contains(this.buttonActiveClass)) {
        buttonValue = event.target.value;
      }
      this.$emit('selected-month', [buttonValue]);
    },
    updatesYearMonths(departureArr) {
      let month = departureArr[0].split('/')[0];
      let year = +departureArr[0].split('/')[1];
      let result = [{ year: year, months: [month] }];
      for (let i = 1; i < departureArr.length; i++) {
        let monthYear = departureArr[i].split('/');
        let currentMonth = monthYear[0];
        let currentYear = +monthYear[1];
        if (result[result.length - 1].year === currentYear) {
          result[result.length - 1].months.push(currentMonth);
        } else {
          result.push({ year: currentYear, months: [currentMonth] });
        }
      }
      return result;
    }
  }
};
</script>
