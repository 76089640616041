<template>
  <div class="ttc-vue-slider vue-range-slider">
    <div class="rangefromto">
      <div class="rangefrom">
        <input
          id="durationfrom"
          v-model="value[0]"
          type="text"
          maxlength="2"
          oninput="this.value=this.value.replace(/[^0-9]/g,'');"
          aria-label="StartDuration"
          :min="min"
          :max="max"
          class="input has-value"
          @change="passValue"
        >
      </div>
      <div class="rangeto">
        <input
          id="durationto"
          v-model="value[1]"
          type="text"
          maxlength="2"
          oninput="this.value=this.value.replace(/[^0-9]/g,'');"
          aria-label="DurationUpto"
          :min="min"
          :max="max"
          class="input has-value"
          @change="passValue"
        >
      </div>
    </div>
  </div>
</template>
<script>
import vueSlider from 'vue-slider-component';
import * as searchConstants from '../../search-constants.js';
import eventBus from '../../vue-functions/event-bus.js';

export default {
  components: { vueSlider },
  props: ['minval', 'maxval', 'dslider'],
  data: function () {
    return {
      value: [searchConstants.DURATIONSLIDERMINVAL, searchConstants.DURATIONSLIDERMAXVAL],
      min: searchConstants.DURATIONSLIDERMINVAL,
      max: searchConstants.DURATIONSLIDERMAXVAL,
      dotSize: 26,
      keyboard: true,
      width: '100%',
      tooltip: false,
      valuesObject: {
        minDuration: searchConstants.DURATIONSLIDERMINVAL,
        maxDuration: searchConstants.DURATIONSLIDERMAXVAL
      }
    };
  },
  mounted: function () {
    if (this.minval) {
      this.value[0] = parseInt(this.minval);
    }
    if (this.maxval) {
      this.value[1] = parseInt(this.maxval);
    }
  },
  created() {
    eventBus.$on('duration-slider-reset', () => {
      this.resetSlider();
    });
  },
  methods: {
    passValue() {
      if (this.value[0] === this.min) {
        this.valuesObject.minDuration = null;
      } else {
        this.valuesObject.minDuration = this.value[0];
      }
      if (this.value[1] === this.max) {
        this.valuesObject.maxDuration = null;
      } else {
        this.valuesObject.maxDuration = this.value[1];
      }
      this.$parent.$emit('durationsliderval', this.valuesObject);
    },
    resetSlider() {
      this.$refs.slider.setValue([0, 40]);
    }
  }
};
</script>
