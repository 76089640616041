import * as searchConstants from '../../search-constants.js';

function setBrandFilterText(valuesArray) {
  if (valuesArray.length < 2) {
    this.$data.settings.filtersText.brandsText = valuesArray[0];
  } else {
    this.$data.settings.filtersText.brandsText =
      '+' + valuesArray.length + searchConstants.FILTERTEXTBRANDS;
  }
}
export default setBrandFilterText;
