<template>
  <div
    class="special-offers-filter is-flex slider-toggle"
    :class="{ disabled: settings.loading }"
  >
    <label
      class="switch inline-center is-relative"
      for="special-offers"
      aria-labelledby="special offers"
    >
      <input
        id="special-offers"
        v-model="isSpecialOffers"
        type="checkbox"
        name="specialOffers"
        @change="setSpecialOffersFlag($event)"
      >
      <div class="slider round" />
    </label>
    <p
      class="inline-center"
      :class="{ filterChecked: filterCheckbox }"
    >
      {{
        settings.filterTripsContent.specialOffersDisplayText
          ? settings.filterTripsContent.specialOffersDisplayText
          : 'Special Offers'
      }}
    </p>
  </div>
</template>
<script>
import EventBus from '../../vue-functions/event-bus';
export default {
  props: {
    settings: {
      type: Object
    }
  },
  data() {
    return {
      isSpecialOffers: false,
      filterCheckbox: false
    };
  },
  created() {
    this.checkDefaultValue();
    EventBus.$on('reset-special-offers-flag', () => {
      this.isSpecialOffers = false;
    });
  },
  methods: {
    setSpecialOffersFlag(e) {
      this.$emit('set-special-offers-flag', this.isSpecialOffers);
      this.$emit('filter-search');
    },
    checkDefaultValue() {
      let specialOffersChecked = this.settings.defaultSpecialOffersFilter;
      let tagsHasSpecialOffers = this.settings.tags === 'SpecialOffer';
      this.isSpecialOffers = specialOffersChecked || tagsHasSpecialOffers;
    }
  }
};
</script>
