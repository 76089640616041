<template>
  <div
    v-if="datamodal"
    class="modal aboutus-modal modal-geq"
    :class="{ 'is-active': active }"
    role="dialog"
    aria-labelledby="aboutus_dialog"
    aria-modal="true"
    @keydown.esc.prevent="closeForm()"
  >
    <div
      class="modal-background"
      @click.prevent="closeForm()"
    />
    <div class="modal-card">
      <div class="modal-form">
        <div class="modal-card-head">
          <a
            class="modal-card-close bg-transparent"
            href="#"
            aria-label="close"
            @click.prevent="closeForm()"
          >
            <i class="fa fa-times fa-lg" />
          </a>
        </div>
        <div class="modal-card-body">
          <div class="aboutus-logo-image">
            <img
              :src="datamodal.logoUrl"
              :alt="datamodal.logoAlt"
            >
          </div>
          <div
            class="aboutus-info"
            v-html="datamodal.aboutUsText"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from '../../vue-functions/event-bus';
import { closeModal } from '../../modals/closeModal';
export default {
  props: ['modaldata'],
  data() {
    return {
      isLoading: false,
      active: false,
      modal: false,
      targetButton: '',
      datamodal: ''
    };
  },
  watch: {
    active() {
      if (this.active) {
        setTimeout(() => {
          const modal = document.querySelector('.aboutus-modal .modal-card-close');
          modal.focus();
        }, 200);
      } else {
        this.selectedButton.focus();
      }
    }
  },
  created: function () {
    EventBus.$on('toggle-aboutus-modal', () => {
      document.documentElement.classList.add('is-clipped');
      this.show();
    });
    EventBus.$on('aboutus-modal-data', (data) => {
      this.datamodal = data;
    });
    if (!this.active) {
      EventBus.$on('update-aboutus-target', (target) => {
        this.targetButton = target;
        this.selectedButton = target;
      });
    }
  },
  mounted() {
    this.closeModal(this.closeModal);
  },
  methods: {
    show() {
      this.active = true;
      this.toggleBodyClass();
    },
    toggleBodyClass() {
      let bodyEl = document.querySelector('body');
      bodyEl.classList.toggle('is-modal-active');
    },
    closeForm() {
      this.active = false;
      document.documentElement.classList.remove('is-clipped');
      this.toggleBodyClass();
      document.querySelector('.modal-card-body').parentElement.classList.remove('scroll-more');
    },
    closeModal
  }
};
</script>
