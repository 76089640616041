import EventBus from '../../vue-functions/event-bus';
import axios from 'axios';

axios.interceptors.response.use(
  function (response) {
    if (response.status === 400) {
      return Promise.reject(response);
    }
    if (response.status === 500) {
      return Promise.reject(response);
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

function launchEasyQuote(tourData) {
  let tour = {
    brand: tourData.brand,
    brandName: tourData.brandName,
    tourName: tourData.tourName,
    aaeCategory: tourData.aaeCategory,
    isCmsTour: tourData.isCmsTour,
    url: tourData.url,
    tourId: tourData.tourId,
    tourOptionId: tourData.tourOptionId,
    isUniworldTour: tourData.isUniworldTour,
    isUbyTour: tourData.isUbyTour
  };
  let tourselecteddate = tourData.tourDate || '';
  EventBus.$emit('TOGGLE_RAQ_MODAL', tour, tourselecteddate);
}
export default launchEasyQuote;
