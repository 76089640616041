<template>
  <button
    v-if="!settings.filtersLocked"
    aria-label="MainSearch"
    class="searchbar-reset-action"
    @click.prevent="resetFiltersClicked()"
  >
    {{ settings.filterTripsContent.clearAllText }}
  </button>
</template>
<script>
export default {
  props: {
    settings: {
      type: Object
    }
  },
  methods: {
    resetFiltersClicked() {
      this.$emit('reset-all-filters');
    }
  }
};
</script>
