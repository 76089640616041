<template>
  <div>
    <section
      v-for="brandSection in brandsdata"
      class="brands subsection"
    >
      <div class="site-section-header">
        <div class="site-section-headerTop">
          <h2
            v-if="brandSection.title"
            class="site-section-title"
          >
            {{ brandSection.title }}
          </h2>
          <p
            v-if="brandSection.subTitle"
            class="site-section-subtitle"
          >
            {{ brandSection.subTitle }}
          </p>
        </div>
        <div class="brands__cta">
          <a
            data-type="general interaction"
            data-name="button"
            class="button is-primary is-fixed-width cta"
            target="_self"
            :href="brandSection.linkUrl"
          >{{ brandSection.linkName }}</a>
        </div>
      </div>

      <div class="brands__content">
        <div
          v-for="brand in brandSection.brands"
          data-type="navigation"
          data-name="brand-pod"
          class="brands__content-item is-full-mobile"
        >
          <a
            :href="brand.brandLinkUrl"
            :aria-label="brand.brandLinkName"
            target="_self"
          >
            <div class="brands__content-section">
              <img
                class="brands__content-image"
                :src="brand.brandLogoUrl"
                :alt="brand.brandLogoAltText ? brand.brandLogoAltText : brand.brandLinkName"
              >
              <button class="button is-primary brands__content-cta">
                {{ brandSection.hoverButton }}
              </button>
            </div>
            <div
              v-if="brand.brandDescriptionText"
              class="brands__content-copy"
            >
              <p>{{ brand.brandDescriptionText }}</p>
            </div>
          </a>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  props: ['brandsdata']
};
</script>
