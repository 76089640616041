<template>
  <portal
    name="quote-ftc-header-button"
    target-el="#notificationbar_cta"
    slim
  >
    <button
      v-show="labels.button"
      class="notificationbar__utils__cta button level-item is-hidden-mobile"
      href="#"
      @click.prevent="toggleModal()"
      v-text="labels.button"
    />
  </portal>
</template>
<script>
import EventBus from '../../vue-functions/event-bus';
export default {
  props: {
    labels: Object
  },
  methods: {
    toggleModal() {
      EventBus.$emit('TOGGLE_FTC_MODAL');
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: 'FTC Header Button Clicked' });
    }
  }
};
</script>
