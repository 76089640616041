function getBrandPodMaxHeight() {
  let brands = document.querySelectorAll('.brands__content-item');
  let maxHeight = 0;

  for (let i = 0; i < brands.length; i++) {
    let currentHeight = brands[i].offsetHeight;
    maxHeight = Math.max(maxHeight, currentHeight);
    brands[i].style.height = maxHeight + 'px';
  }
}
export default getBrandPodMaxHeight;
