import * as searchConstants from '../../search-constants.js';
function setMonthFilterText(valuesArray) {
  if (valuesArray.length < 2) {
    this.$data.settings.filtersText.monthsText = valuesArray[0];
  } else {
    this.$data.settings.filtersText.monthsText =
      '+' + valuesArray.length + searchConstants.FILTERTEXTMONTHS;
  }
}
export default setMonthFilterText;
