import websiteSearch from '../vue-apps/app-search';
import searchFilterToggle from './search-filter-toggle';

function subSearch() {
  let searchSubsetLaunched = false;
  let searchApp = document.querySelector('#app-search');

  function runSearch() {
    if (searchSubsetLaunched) {
      return false;
    }

    websiteSearch();
    searchFilterToggle();
    searchSubsetLaunched = true;
  }

  document.addEventListener('DOMContentLoaded', function () {
    if (searchApp) {
      runSearch();
    }
  });
}
export default subSearch;
