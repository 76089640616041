const getCurrentScreenSize = () => {
  const head = document.getElementsByTagName('head')[0];
  let computed;

  if (window.getComputedStyle) {
    computed = window.getComputedStyle(head, null);
  } else if (document.documentElement.currentStyle) {
    computed = head.currentStyle;
  }

  let size = computed.fontSize ? parseInt(computed.fontSize, 10) : null;
  let screenDef = null;

  switch (size) {
    case 10:
      screenDef = 'ss'; // small screen
      break;
    case 20:
      screenDef = 'ms'; // midi screen
      break;
    case 30:
      screenDef = 'ls'; // large screen
      break;
    case 40:
      screenDef = 'xls'; // extra large screen
      break;
    default:
      screenDef = null; // undefined screen size
  }

  return screenDef;
};

export default getCurrentScreenSize;
