<template>
  <article class="accordion">
    <header
      :id="`accordian-${index}`"
      class="accordion-header"
      role="button"
      tabindex="0"
      :aria-expanded="index === 0 ? 'true' : 'false'"
      :aria-controls="`accordian-content-${index}`"
    >
      <div>
        <p class="accordion-label">
          Day {{ day.startDay }}
        </p>
        <h3 class="accordion-title">
          {{ day?.title }}
        </h3>
      </div>
      <span class="icon">
        <i class="fas fa-chevron-up" />
        <i class="fas fa-chevron-down" />
      </span>
    </header>
    <div
      :id="`accordian-content-${index}`"
      class="accordion-body"
      :aria-labelledby="`accordian-${index}`"
    >
      <div class="accordion-content">
        <div class="columns">
          <div class="column is-7-desktop">
            <div v-if="hasDayText">
              <div
                v-for="text in day.text"
                :key="text"
                class="accordion-text"
              >
                <p
                  v-if="text"
                  v-html="text"
                />
              </div>
            </div>
            <uby-excursions
              v-if="isUBy && !hasDayText"
              :ubyincluded="uByIncluded"
              :ubyplus="uByUPlus"
            />
          </div>

          <div class="column is-5-desktop details">
            <ul>
              <li v-if="day.accommodation || (day.accommodations && day.accommodations[0]?.title)">
                <span class="icon"><i class="fas fa-bed" /></span>
                <p class="label">
                  {{ dictionarymodel.accommodation }}
                </p>
                <p class="detail">
                  {{ tourapi.isC1Tour ? day.accommodations[0]?.title : day.accommodation }}
                </p>
                <br>
              </li>
              <li v-if="day.meals && day.meals.length > 0">
                <span class="icon"><i class="fas fa-utensils" /></span>
                <p class="label">
                  Meals
                </p>
                <p
                  v-for="meal in day.meals"
                  :key="meal.index"
                  class="detail"
                >
                  {{ meal }}
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div
          v-if="day.dayFootnote"
          class="columns"
        >
          <div class="column is-7-desktop">
            <span class="is-italic">{{ day.dayFootnote }}</span>
          </div>
        </div>
        <div v-if="isUniworld">
          <div
            v-if="dayThemesArray.toString().includes('Included - Featured')"
            class="column details"
          >
            <h4 class="subtitle is-size-6 is-marginless">
              Featured Excursions:
            </h4>
            <div
              v-for="excursion in day?.excursions"
              :key="excursion.index"
            >
              <div v-if="excursion.type === 'Included - Featured'">
                <a
                  v-if="excursion.details"
                  href="#"
                  class="itinerary-modal-trigger"
                  :data-excursion-title="excursion?.title"
                  :data-excursion-details="excursion.details"
                >{{ excursion?.title }}</a>
                <span v-if="!excursion.details">{{ excursion?.title }}</span>
              </div>
            </div>
          </div>
          <div
            v-if="uniworldDayChoices.length && uniworld"
            class="column details"
          >
            <h4 class="subtitle is-size-6 is-marginless">
              Choice of:
            </h4>
            <div
              v-for="(excursion, index) in uniworldDayChoices"
              :key="index"
            >
              <p>
                <span
                  v-if="index == uniworldDayChoices.length - 1"
                  class="subtitle"
                >or</span>
              </p>
              <a
                v-if="excursion.details"
                href="#"
                class="itinerary-modal-trigger"
                :data-excursion-title="excursion?.title"
                :data-excursion-details="excursion.details"
              >{{ excursion?.title }}</a>
              <span v-if="!excursion.details">{{ excursion?.title }}</span>
            </div>
          </div>
          <div
            v-if="dayThemesArray.toString().includes('Theme')"
            class="column details"
          >
            <h4 class="subtitle is-size-6 is-marginless">
              {{ dayThemeName }}
            </h4>
            <div
              v-for="excursion in day?.excursions"
              :key="excursion.index"
            >
              <div v-if="excursion.type.includes('Theme')">
                <a
                  v-if="excursion.details"
                  href="#"
                  class="itinerary-modal-trigger"
                  :data-excursion-title="excursion?.title"
                  :data-excursion-details="excursion.details"
                >{{ excursion?.title }}</a>
                <span v-if="!excursion.details">{{ excursion?.title }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="isUBy && hasDayText"
          class="column is-7-desktop"
        >
          <uby-excursions
            :ubyincluded="uByIncluded"
            :ubyplus="uByUPlus"
          />
        </div>
      </div>
      <itinerary-dayHighlights
        v-if="(tourapi?.isC1Tour || tourapi?.isCmsTour) && day.dayHighlights?.length > 0"
        :day-highlights="day.dayHighlights"
      />
    </div>
  </article>
</template>
<script>
import UByExcurtions from './uby-excursions.vue';
import ItineraryDayHighlights from '../../vue-components/itinerary/itinerary-dayHighlights.vue';

export default {
  components: {
    'uby-excursions': UByExcurtions,
    'itinerary-dayHighlights': ItineraryDayHighlights
  },
  props: ['day', 'index', 'uniworld', 'dictionarymodel', 'tourapi'],
  data() {
    return {
      dayThemesArray: [],
      dayThemeName: '',
      uniworldDayChoices: [],
      isUniworld: false,
      isUBy: false,
      uByIncluded: [],
      uByUPlus: []
    };
  },
  computed: {
    hasDayText() {
      if (this.day.text) {
        if (this.day.text[0]) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  },
  created() {
    if (this.uniworld) {
      this.populateThemesArray();
    }
  },
  methods: {
    populateThemesArray() {
      let thisThemeArray = [];
      let includedArray = [];
      let uPlusArray = [];
      for (let i = 0; i < this.day.excursions.length; i++) {
        thisThemeArray.push(this.day.excursions[i].type);
        this.dayThemesArray.push(this.day.excursions[i].type);
        if (
          this.day.excursions[i].type.includes('Choice') ||
          this.day.excursions[i].type.includes('Featured')
        ) {
          this.isUniworld = true;
        }
        if (this.day.excursions[i].type.includes('Theme')) {
          this.dayThemeName = this.day.excursions[i].excursionTheme;
        }
        if (this.day.excursions[i].type === 'Included - Choice') {
          this.uniworldDayChoices.push(this.day.excursions[i]);
        }
        if (
          this.day.excursions[i].type === 'Included' ||
          this.day.excursions[i].type === 'U+ Excursion'
        ) {
          this.isUBy = true;
          if (this.day.excursions[i].type === 'Included') {
            includedArray.push(this.day.excursions[i]);
          }
          if (this.day.excursions[i].type === 'U+ Excursion') {
            uPlusArray.push(this.day.excursions[i]);
          }
          this.uByIncluded = includedArray;
          this.uByUPlus = uPlusArray;
        }
      }
    }
  }
};
</script>
