import jQuery from 'jquery';

function smoothScroll() {
  jQuery('.ctaButton').on('click', function (event) {
    event.preventDefault();
    jQuery('html,body').animate({ scrollTop: $(this.hash).offset().top }, 500);
  });
}

export default smoothScroll;
