'use strict';
export const closeModal = function (closeFunction) {
  const key = 27;
  document.addEventListener('DOMContentLoaded', function () {
    window.addEventListener('keydown', (e) => {
      if (e.key == key) {
        closeFunction();
      } else if (e.keyIdentifier == key) {
        closeFunction();
      } else if (e.keyCode === key) {
        closeFunction();
      }
    });
  });
};
