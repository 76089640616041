import desktopNavJS from './main-nav-helper/main-nav-helper-desktop';
import mobileNavJS from './main-nav-helper/main-nav-helper-mobile';
import destinationMobileMenu from './destinations-mobile'; // VS menu
import destinationMainMenu from './destinations-desktop-mainMenu';
import debounce from 'lodash.debounce';

function mainNavHelper() {
  function loadNavHelper() {
    let windowWidth = window.innerWidth;

    if (windowWidth <= 1024) {
      mobileNavJS();
      destinationMobileMenu();
    } else {
      desktopNavJS();
    }
    destinationMainMenu();
  }
  loadNavHelper();
  window.onresize = debounce(loadNavHelper, 100);
}

export default mainNavHelper;
