<template>
  <portal
    name="vue-website-search"
    target-el="#search-field"
  >
    <!-- this is coming from vue vue component -->
    <div class="search__inputholder control">
      <div id="search-field-wrapper">
        <input
          id="searchInput"
          v-model="tripdestination"
          autocomplete="off"
          aria-label="searchInput"
          class="input is-size-7"
          title="Search Input"
          type="text"
          :placeholder="searchplaceholder"
          @keyup="fetchSearchParams"
          @keyup.enter="searchClick"
        >
      </div>
      <div
        v-show="tripdestination.length > 2 && showSuggestions"
        ref="searchresultscontent"
        class="search-field-result-content shadow-deep"
      />
    </div>
    <div class="control">
      <button
        aria-label="searchButton"
        class="button cta is-primary"
        @click="searchClick"
      >
        <span class="icon is-small">
          <i class="fas fa-search" />
        </span>
        <span
          class="search-btn-value is-size-7"
          v-html="searchbuttonlabel"
        />
      </button>
    </div>
  </portal>
</template>

<script>
// axios for http request
import axios from 'axios';
import * as searchConstants from '../../search-constants.js';

const AUTOCOMPLETEURL = searchConstants.LOCATION_STRING + searchConstants.AUTO_COMPLETE_PATH;
const debounce = require('lodash.debounce');
export default {
  props: ['dest', 'searchterm', 'searchbuttonlabel', 'searchplaceholder'],
  data: function () {
    return {
      tripdestination: '',
      searchDetail: {
        term: false,
        searchType: false
      },
      suggestions: '',
      showSuggestions: false,
      siteNodeId: ''
    };
  },
  beforeMount() {
    this.siteNodeId = '&siteNodeId=' + searchConstants.browserWindow.Cms.CurrentSiteNodeId;
  },
  created() {
    if (this.searchterm === 'text=' && this.dest.length > 0) {
      this.tripdestination = decodeURIComponent(this.dest);
    }
  },
  mounted() {
    let searchField = document.querySelector('#search-field');
    let searchClickOutside = (e) => {
      let isClickInside = searchField.contains(e.target);
      if (!isClickInside) {
        this.$refs.searchresultscontent.innerHTML = '';
        this.showSuggestions = false;
      }
    };
    document.addEventListener('click', searchClickOutside);
  },
  methods: {
    fetchSearchParams(event) {
      if (event.keyCode === 13) {
        // if the event is triggered by the enter key here, user has clicked to search and we want to hide autosuggest
        // so return out of function
        return;
      }
      if (this.tripdestination.length > 2) {
        const autoSearch = debounce(() => {
          axios
            .get(AUTOCOMPLETEURL + this.tripdestination + this.siteNodeId, { timeout: 10000 })
            .then((response) => {
              this.suggestions = response.data;
              this.renderResults(this.suggestions);
            })
            .catch((error) => {
              // error handling needs some thought from TTC
            });
        }, 500);
        autoSearch();
      } else {
        this.$refs.searchresultscontent.innerHTML = '';
      }
    },
    renderResults(searchSuggestions) {
      this.showSuggestions = true;
      this.$refs.searchresultscontent.innerHTML = searchSuggestions;
      let searchResults = document.querySelector('.search-field-result-content');
      let buttons = searchResults.querySelectorAll('button');

      for (let i = 0; i < buttons.length; i++) {
        let currentBtn = buttons[i];
        currentBtn.addEventListener('click', () => {
          if (currentBtn.dataset.country) {
            this.searchDetail.term = currentBtn.dataset.country;
            this.searchDetail.searchType = searchConstants.DEFAULTSEARCHTERM;
          }
          if (currentBtn.dataset.continent) {
            this.searchDetail.term = currentBtn.dataset.continent;
            this.searchDetail.searchType = searchConstants.REGIONSSEARCHTERM;
          }
          if (currentBtn.dataset.tour) {
            this.searchDetail.term = currentBtn.dataset.tour;
            this.searchDetail.searchType = searchConstants.TOURSEARCHTERM;
          }
          this.emitSearch();
        });
      }
    },
    searchClick(event) {
      this.searchDetail.term = this.tripdestination;
      this.searchDetail.searchType = searchConstants.FREETEXTSEARCHTERM;
      this.emitSearch();
    },
    emitSearch() {
      this.showSuggestions = false;
      this.$emit('searchclicked', this.searchDetail);
    },
    resetFreeTextField() {
      this.tripdestination = '';
    }
  }
};
</script>
