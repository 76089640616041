<template>
  <div class="ttc-vue-slider vue-price-slider">
    <vue-slider
      ref="priceslider"
      v-model="value"
      :show="pslider"
      :tooltip="tooltip"
      :width="width"
      :dot-size="dotSize"
      :min="min"
      :max="max"
      :use-keyboard="keyboard"
      @callback="passValue"
    />
    <div class="rangefromto">
      <div class="rangefrom">
        <span class="range-currency">{{ currency }}</span>
        <input
          id="pricefrom"
          v-model="value[0]"
          type="text"
          maxlength="5"
          oninput="this.value=this.value.replace(/[^0-9]/g,'');"
          aria-label="StartPrice"
          :min="min"
          :max="max"
          class="input has-value"
          @change="passValue"
        >
      </div>
      <div class="rangeto">
        <span>{{ currency }}</span>
        <input
          id="priceto"
          v-model="value[1]"
          type="text"
          maxlength="5"
          oninput="this.value=this.value.replace(/[^0-9]/g,'');"
          aria-label="PriceUpto"
          :min="min"
          :max="max"
          class="input has-value"
          @change="passValue"
        >
        <span
          v-show="value[1] === max"
          class="vue-price-slider-max"
        >+</span>
      </div>
    </div>
  </div>
</template>

<script>
import vueSlider from 'vue-slider-component';
import * as searchConstants from '../../search-constants.js';
import eventBus from '../../vue-functions/event-bus.js';

export default {
  components: { vueSlider },
  props: ['currency', 'minval', 'maxval', 'pslider'],
  data: function () {
    return {
      value: [searchConstants.PRICESLIDERMINVAL, searchConstants.PRICESLIDERMAXVAL],
      min: searchConstants.PRICESLIDERMINVAL,
      max: searchConstants.PRICESLIDERMAXVAL,
      dotSize: 24,
      keyboard: true,
      width: '49%',
      tooltip: false,
      valuesObject: {
        minPrice: 0,
        maxPrice: 0
      }
    };
  },
  mounted() {
    if (this.minval) {
      this.value[0] = this.minval;
    }

    if (this.maxval) {
      this.value[1] = this.maxval;
    }
  },
  created() {
    eventBus.$on('price-slider-reset', () => {
      this.resetSlider();
    });
  },
  methods: {
    passValue() {
      if (this.value[0] == this.min) {
        this.valuesObject.minPrice = null;
      } else {
        this.valuesObject.minPrice = this.value[0];
      }

      if (this.value[1] == this.max) {
        this.valuesObject.maxPrice = null;
      } else {
        this.valuesObject.maxPrice = this.value[1];
      }
      this.$parent.$emit('price-slider-val', this.valuesObject);
    },
    resetSlider() {
      this.$refs.priceslider.setValue([this.min, this.max]);
    }
  }
};
</script>
