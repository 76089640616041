<template>
  <div class="highlitghts">
    <div
      v-if="dayHighlights.length > 0"
      class="highlitghts__daylights"
    >
      <carousel-3d
        :count="dayHighlights.length"
        :controls-visible="true"
        controls-prev-html="prev"
        controls-next-html="next"
        :controls-width="30"
        :controls-height="60"
        :autoplay="true"
        :clickable="false"
        :space="1300"
        :inverse-scaling="1800"
        :width="640"
        :height="400"
        :perspective="0"
        :display="3"
        :border="0"
        :loop="true"
        @after-slide-change="onAfterSlideChange"
      >
        <slide
          v-for="(item, i) in dayHighlights"
          :index="i"
        >
          <figure>
            <img
              :src="item.url"
              :alt="item.alt"
            >
          </figure>
        </slide>
      </carousel-3d>
      <div class="highlitghts__daylights__bullets">
        <button
          class="prev"
          @click="prevClick"
        >
          <i class="fas fa-arrow-left" />
        </button>
        <ul v-if="dayHighlights.length <= 9">
          <li
            v-for="i in dayHighlights"
            :index="i"
          >
            {{ i }}
          </li>
        </ul>
        <div
          v-if="dayHighlights.length > 9"
          class="highlitghts__daylights__bullets-roller"
        >
          <div class="bg" />
          <span :style="{ width: width + '%' }" />
        </div>
        <button
          class="next"
          @click="nextClick"
        >
          <i class="fas fa-arrow-right" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';
export default {
  components: {
    'carousel-3d': Carousel3d,
    slide: Slide
  },
  props: ['itinerarymodel'],
  data() {
    return { width: 0, initWidth: 0 };
  },
  computed: {
    dayHighlights() {
      let data = [];
      this.itinerarymodel?.itinerary.map((item) => {
        item.dayHighlights?.map((day) => {
          if (day.imageUrls) {
            data.push({
              url: day.imageUrls,
              alt: day.title
            });
          }
        });
      });
      return data;
    }
  },
  created() {
    setTimeout(() => {
      let sliderCntr = document.querySelector('.highlitghts__daylights .carousel-3d-container');
      let bulletsCntr = document.querySelector('.highlitghts__daylights__bullets');
      if (sliderCntr) {
        sliderCntr.append(bulletsCntr);
      }
      this.initWidth = 100 / this.dayHighlights.length;
    }, 1000);
  },
  methods: {
    onAfterSlideChange(index) {
      if (this.dayHighlights.length <= 9) {
        let bullets = document.querySelectorAll('.highlitghts__daylights__bullets li');
        bullets.forEach((item) => {
          item.classList.remove('active');
        });
        bullets[index || 0]?.classList.add('active');
      }
      this.width = this.initWidth * (index + 1);
    },
    nextClick() {
      document.querySelector('.highlitghts__daylights .carousel-3d-controls .next').click();
    },
    prevClick() {
      document.querySelector('.highlitghts__daylights .carousel-3d-controls .prev').click();
    }
  }
};
</script>
