<template>
  <div class="embedded-search embedded-search-container searchbar">
    <div class="embedded-search_location input-wrapper searchbar-control">
      <div class="searchbar-control">
        <search-destination-selector
          :embeddedsearchcontent="embeddedSearchContent"
          :settings="settings"
          :filter-visibility="filterVisibility"
          @set-filter-visibility="setFilterVisibility"
          @destination-filter-actions="destinationFilterActions"
          @destinations-region-click="clearDestinationValues"
          @destinations-region-search="setRegionSearch"
          @destinations-filter="getSelectedDestinations"
          @reset-destination-filters="resetDestinationFilters"
        />
      </div>
    </div>
    <div class="embedded-search_dates input-wrapper searchbar-control">
      <div class="searchbar-control">
        <search-date-duration
          :embeddedsearchcontent="embeddedSearchContent"
          :settings="settings"
          :filter-visibility="filterVisibility"
          @set-filter-visibility="setFilterVisibility"
          @duration-filter-actions="durationFilterActions"
          @months="getSelectedMonths"
          @durationsliderval="getDurationSliderValues"
          @reset-duration-filters="resetDurationFilters"
        />
      </div>
    </div>
    <button
      class="embedded-search_button button is-secondary cta"
      @click.prevent="exitToMainSearch"
    >
      {{ embeddedSearchContent.searchText }}
    </button>
  </div>
</template>
<script>
// vue components
import searchDestinationSelector from '../search/search-destination-selector.vue';
import searchDateDuration from '../search/search-date-duration.vue';

// constants for use
import * as searchConstants from '../../search-constants.js';

// filtering & searching
import makeSearchFilters from '../../search-functions/global-filter-functions/make-search-filters';
import resetSearch from '../../search-functions/search-operations/reset-search';
import setFilterVisibility from '../../search-functions/global-filter-functions/set-filter-visibility';
import setRegionSearch from '../../search-functions/destination-filters/set-region-search';
import filterSearch from '../../search-functions/global-filter-functions/filter-search';
import applyCmsParameters from '../../search-functions/search-operations/apply-cms-parameters';
import fetchSearch from '../../search-functions/search-operations/fetch-search';
import matchApiDestinations from '../../search-functions/global-filter-functions/match-api-destinations';
import exitToMainSearch from '../../search-functions/search-operations/exit-to-main-search'; /* used */
import constructSearchTerm from '../../search-functions/search-operations/construct-search-term'; /* used */

// url management function
import manageUrlState from '../../search-functions/url-handling/manage-url-state';

// date duration filters functions
import getDurationSliderValues from '../../search-functions/date-duration-filters/get-duration-slider-values';
import setDurationFilterText from '../../search-functions/date-duration-filters/set-duration-filter-text';
import setMonthFilterText from '../../search-functions/date-duration-filters/set-month-filter-text';
import resetDurationFilters from '../../search-functions/date-duration-filters/reset-duration-filters';
import durationFilterActions from '../../search-functions/date-duration-filters/duration-filter-actions';
import handleApplyClick from '../../search-functions/global-filter-functions/handle-apply-click';
import getSelectedMonths from '../../search-functions/date-duration-filters/get-selected-months';

// destination filter actions
import getSelectedDestinations from '../../search-functions/destination-filters/get-selected-destinations';
import setDestinationFilterText from '../../search-functions/destination-filters/set-destination-filter-text';
import destinationFilterActions from '../../search-functions/destination-filters/destination-filter-actions';
import resetDestinationFilters from '../../search-functions/destination-filters/reset-destination-filters';
import clearDestinationValues from '../../search-functions/destination-filters/clear-destination-values';

// ui behaviour functions
import scrollToFilterSmallScreen from '../../search-functions/global-filter-functions/scroll-to-filter-small-screen';
import doSearchScroll from '../../search-functions/ui-behaviour/do-search-scroll';
import getCurrentScreenSize from '../../ui-functions/get-current-screen-size';
import scrollToElement from '../../search-functions/ui-behaviour/scroll-to-element';
import clickOutsideFilters from '../../search-functions/ui-behaviour/click-outside-filters';

// googleAnalytics tracking function
import sendGoogleAnalyticsSearchCriteria from '../../search-functions/search-operations/send-google-analytics-search-criteria';

export default {
  components: {
    searchDestinationSelector,
    searchDateDuration
  },
  props: ['appId'],
  data() {
    return {
      settings: {
        loading: false,
        filtersText: {
          dateDurationDefault: '',
          durationText: false,
          monthsText: false,
          destinationsDefault: '',
          destinationsText: false
        },
        filtersLocked: false,
        dynamicFilters: {
          durationFilter: '',
          destinationFilter: '',
          monthFilter: '',
          durationSlider: 'search-duration-slider',
          destinationFilterComponent: 'search-destination-filters',
          monthPickerComponent: 'search-calendar'
        },
        currentSearchPrefix: searchConstants.DEFAULTSEARCHTERM,
        currentDestinations: '',
        currentContinent: false,
        filterActions: {
          destinationActionClicked: 'setFilterVisibility',
          durationActionClicked: 'setFilterVisibility'
        },
        filters: {
          minDuration: false,
          maxDuration: false,
          monthsSelected: false
        },
        filtersValues: {
          minDurationVal: false,
          maxDurationVal: false,
          monthsVal: false
        },
        filterTripsContent: {},
        searchResultContent: {}
      },
      filterVisibility: {
        showDurationFilter: false,
        showDestinationFilter: false
      },
      embeddedSearchContent: {},
      tourResults: [],
      urlParams: '',
      parentSearch: false,
      embeddedSearch: true,
      locationString: searchConstants.LOCATION_STRING
    };
  },
  beforeMount() {
    this.settings.filterTripsContent =
      searchConstants.browserWindow.Cms.searchAppParams.filterTripsContent;
    this.settings.filtersText.dateDurationDefault =
      searchConstants.browserWindow.Cms.searchAppParams.embeddedSearchContent.datesPlaceHolderText;
    this.settings.filtersText.destinationsDefault =
      searchConstants.browserWindow.Cms.searchAppParams.embeddedSearchContent.destinationPlaceHolderText;
    this.embeddedSearchContent =
      searchConstants.browserWindow.Cms.searchAppParams.embeddedSearchContent;
  },
  mounted() {
    this.clickOutsideFilters();
  },
  methods: {
    filterSearch,
    makeSearchFilters,
    applyCmsParameters,
    getDurationSliderValues,
    setDurationFilterText,
    setMonthFilterText,
    resetDurationFilters,
    getSelectedDestinations,
    destinationFilterActions,
    resetDestinationFilters,
    setFilterVisibility,
    setDestinationFilterText,
    durationFilterActions,
    setRegionSearch,
    scrollToFilterSmallScreen,
    resetSearch,
    doSearchScroll,
    getCurrentScreenSize,
    constructSearchTerm,
    manageUrlState,
    fetchSearch,
    matchApiDestinations,
    exitToMainSearch,
    sendGoogleAnalyticsSearchCriteria,
    handleApplyClick,
    scrollToElement,
    clickOutsideFilters,
    clearDestinationValues,
    getSelectedMonths
  }
};
</script>
