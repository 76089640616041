<template>
  <div class="itinerary section">
    <div class="itinerary-header" />
    <div class="itinerary-body">
      <div
        v-if="!itinerarymodel?.isDefaultMapImage"
        class="map itinerary-map"
      >
        <div
          id="app-mapModal"
          data-type="general interaction"
          data-name="map"
          class="map-holder"
        >
          <map-modal
            :map-type-image="true"
            :mapurl="itinerarymodel?.mapImageUrl"
            :tourtype="raqmodel.tour"
          />
          <div class="map-image-wrap new-section">
            <map-image-zoom
              :display-close-modal-button="false"
              :image-src="itinerarymodel?.mapImageUrl?.split('?')[0]"
              :image-default-class="imageDefaultClass"
              :image-additional-class="raqmodel.tour.isV4Tour ? 'v4map' : 'c1Map'"
              :image-alt="itinerarymodel?.mapImageAltTag"
            />
          </div>
        </div>
      </div>
      <div
        id="itinerary-section"
        class="lead itinerary-days"
        data-type="general interaction"
        data-name="itinerary"
      >
        <section class="accordions">
          <div class="accordion-tabs-wrap">
            <h3 class="itinerary-text">
              {{ dictionarymodel?.itinerary }}
            </h3>
            <div class="accordion-tabs-container">
              <span
                class="accordion-tab accordion-collapse"
                role="button"
                tabindex="0"
              >{{
                dictionarymodel.collapseAll
              }}</span>
              <span
                class="accordion-tab accordion-expand"
                role="button"
                tabindex="0"
              >{{
                dictionarymodel.expandAll
              }}</span>
            </div>
          </div>
          <div>
            <itinerary-accordion
              v-for="(day, index) in itinerarymodel.itinerary"
              :key="index"
              :itinerarymodel="itinerarymodel"
              :dictionarymodel="dictionarymodel"
              :day="day"
              :index="index"
              :tourapi="raqmodel.tour"
            />
          </div>
        </section>
      </div>
    </div>

    <div
      id="itinerary-modal"
      class="itinerary-modal modal wide-modal itinerary-modal"
    >
      <div class="modal-background" />
      <div class="modal-card">
        <div class="modal-form">
          <div class="modal-card-head">
            <p class="modal-card-title is-size-6">
              Title
            </p>
            <div class="modal-card-close">
              <i class="fa fa-times fa-lg" />
            </div>
          </div>
          <form>
            <section class="modal-card-body">
              <div class="modal-card-content" />
            </section>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ItineraryAccordion from '../../vue-components/itinerary/itinerary-accordion.vue';
import MapModal from '../../vue-components/map/map-modal.vue';
import MapImageZoom from '../../vue-components/map/map-image-zoom.vue';
export default {
  name: 'ItinerarySection',
  components: {
    'itinerary-accordion': ItineraryAccordion,
    'map-modal': MapModal,
    'map-image-zoom': MapImageZoom
  },
  props: ['itinerarymodel', 'raqmodel', 'dictionarymodel'],
  data() {
    return {
      reverseAccordionActive: false,
      imageDefaultClass: 'itinerary-map-image fullimage'
    };
  }
};
</script>
