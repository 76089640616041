import * as searchConstants from '../../search-constants.js';

function getPriceSliderValues(valObj) {
  // function expects an object with 1 or 2 values minPrice, and or maxPrice
  // if those values are set in the object
  // we set the price filter and it's associated vales to those received
  if (valObj.minPrice) {
    this.$data.settings.filtersValues.minPriceVal = valObj.minPrice;
    this.$data.settings.filters.minPrice =
      searchConstants.MIN_PRICE_SEARCH_PREFIX + valObj.minPrice;
  }
  if (valObj.maxPrice) {
    this.$data.settings.filtersValues.maxPriceVal = valObj.maxPrice;
    this.$data.settings.filters.maxPrice =
      searchConstants.MAX_PRICE_SEARCH_PREFIX + valObj.maxPrice;
  }
  // if the values are not present in the object, we reset the filter values back to false
  if (!valObj.maxPrice) {
    this.$data.settings.filtersValues.maxPriceVal = false;
    this.$data.settings.filters.maxPrice = false;
  }
  if (!valObj.minPrice) {
    this.$data.settings.filtersValues.minPriceVal = false;
    this.$data.settings.filters.minPrice = false;
  }
  //  kick off the function to set the filter button text based on the object
  this.setPriceFilterText(valObj);
}
export default getPriceSliderValues;
