import $ from 'jquery';
import testIe from './test-ie';

function fixIEImages() {
  if (testIe()) {
    let items = $(document).find('.is-background');
    $(items).each(function () {
      let $container = $(this.parentNode),
        imgUrl = $(this).prop('src');
      if (imgUrl) {
        $container.css('backgroundImage', 'url(' + imgUrl + ')').addClass('custom-object-fit');
      }
    });
  }
}
export default fixIEImages;
