import eventBus from '../../vue-functions/event-bus';

function resetDurationFilters() {
  eventBus.$emit('duration-slider-reset');
  eventBus.$emit('duration-calendar-reset');

  this.$data.settings.filters.minDuration = false;
  this.$data.settings.filters.maxDuration = false;
  this.$data.settings.filters.monthsSelected = false;
  this.$data.settings.filtersText.durationText = false;
  this.$data.settings.filtersText.monthsText = false;
}
export default resetDurationFilters;
