function setSpecialOffersFlag(flagState) {
  if (flagState) {
    if (this.$data.settings.filters.tags.indexOf('SpecialOffer') === -1) {
      this.$data.settings.filters.tags =
        this.$data.settings.filters.tags.indexOf('tags=') >= 0
          ? this.$data.settings.filters.tags + ' SpecialOffer'
          : 'tags=SpecialOffer';
      this.$data.settings.filters.tags = this.$data.settings.filters.tags.trim();
    }
  } else {
    this.$data.settings.filters.tags = this.$data.settings.filters.tags
      .replace('SpecialOffer', '')
      .trim();
    if (this.$data.settings.filters.tags === 'tags=') {
      this.$data.settings.filters.tags = '';
    }
  }
}
export default setSpecialOffersFlag;
