import $ from 'jquery';
function featureExpand() {
  const feature = $('.feature');

  if (feature) {
    feature.each(function () {
      let featureInner = $(this).find('.feature-inner');
      let featureTextContainerHeight = featureInner.find('.feature-text-container').height();
      if (featureTextContainerHeight > 300) {
        featureInner.addClass('hover-expand');
      }
    });
  }
}

export default featureExpand;
