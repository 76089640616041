<template>
  <div class="is-relative">
    <div class="container">
      <dealItem
        v-for="item in deals"
        :key="item.title"
        :row="item"
        :labels="labels"
        :ongoing="ongoing"
      />
    </div>
    <div
      v-if="showmore"
      class="search-results-show-more grey specialslistings__footer has-text-centered"
    >
      <div class="inner">
        <button
          class="button is-subtle"
          :class="{ 'is-loading': isloading }"
          @click="$emit('load-more-clicked')"
        >
          {{ labels.button }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import dealItem from './deal-item.vue';
export default {
  components: {
    dealItem
  },
  props: ['deals', 'labels', 'ongoing', 'showmore', 'isloading'],
  mounted: function () {
    this.dealsExist();
  },
  methods: {
    dealsExist() {
      let dealsCount = document.querySelectorAll(
        '.current-specials-container .ttc-specials-items'
      ).length;
      if (dealsCount > 0) {
        $('.current-specials-header').removeClass('hidden');
        $('.current-specials-container').removeClass('hidden');
      }
    }
  }
};
</script>
