<template>
  <div class="refine-filter-search">
    <a
      href="#tour-filters-section"
      class="scrollToAnchor"
      @click="scrollToAnchor"
    >
      <span>Refine your Search </span>
      <i class="fa fa-magnifying-glass" />
    </a>
  </div>
</template>

<script>
export default {
  name: 'RefineSearch',
  data() {
    return {};
  },
  methods: {
    scrollToAnchor(e) {
      e.preventDefault();
      let link = e.target.getAttribute('href');
      let offsetTop = document.querySelector(link).offsetTop;

      scroll({
        top: offsetTop,
        behavior: 'smooth'
      });
    }
  }
};
</script>
