<template>
  <div
    :class="{ 'is-active': filterVisibility.showPriceFilter }"
    class="dropdown"
  >
    <div class="dropdown-trigger">
      <a
        href="#"
        class="button is-filter"
        aria-haspopup="true"
        aria-controls="dropdown-prices"
        :disabled="settings.loading"
        :class="{ 'is-filter-active': settings.filtersText.priceText }"
        @click.prevent="$emit('price-filter-actions')"
      >
        <span v-if="!settings.filtersText.priceText">{{
          settings.filterTripsContent.priceMenuText
        }}</span>
        <span v-if="settings.filtersText.priceText">{{ settings.filtersText.priceText }}</span>
        <span
          v-if="!settings.filtersLocked"
          class="icon is-small"
        >
          <i
            class="fas fa-chevron-down"
            aria-hidden="true"
          />
          <i
            class="fas fa-chevron-up"
            aria-hidden="true"
          />
        </span>
      </a>
    </div>
    <div
      id="dropdown-prices"
      class="dropdown-menu dropdown-prices"
      role="presentation"
    >
      <div class="dropdown-header">
        <div class="dropdown-header-wrap">
          <div class="dropdown-header-data">
            <h4 class="dropdown-title">
              Budget Per Person
            </h4>
          </div>
          <div class="dropdown-header-close">
            <button
              class="dropdown-close"
              @click.prevent="$emit('set-filter-visibility')"
            >
              <span class="is-sr-only">Close Menu</span>
              <span
                class="dropdown-close__icon delete"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
        <div class="rangesliderholder">
          <search-price-slider
            :currency="currency"
            :pslider="filterVisibility.showPriceFilter"
            :minval="settings.filtersValues.minPriceVal"
            :maxval="settings.filtersValues.maxPriceVal"
          />
        </div>
      </div>
      <div class="dropdown-footer search-footer">
        <div class="buttonholder search-clear-apply">
          <button
            class="btn-clear"
            href="#"
            @click.prevent="$emit('reset-price-slider')"
          >
            Clear Selection
          </button>
          <button
            class="btn-apply"
            @click.prevent="$emit('filter-search')"
          >
            Apply Selection
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import searchPriceSlider from '../../vue-components/search/search-price-slider.vue';

export default {
  components: {
    searchPriceSlider
  },
  props: {
    settings: {
      type: Object
    },
    filterVisibility: {
      type: Object
    },
    products: {
      type: Array
    },
    currency: {
      type: String
    }
  }
};
</script>
