function clickOutsideFilters() {
  const searchElement = document.querySelector('.searchbar');

  document.addEventListener('click', (event) => {
    let isClickInside = searchElement.contains(event.target);

    if (!isClickInside) {
      let filterVisObj = this.$data.filterVisibility;
      let hidefilters = false;

      Object.keys(filterVisObj).forEach(function (key) {
        if (filterVisObj[key] === true) {
          hidefilters = true;
        }
      });

      if (hidefilters) {
        this.setFilterVisibility();
      }
    }
  });
}
export default clickOutsideFilters;
