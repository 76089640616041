<template>
  <div class="level-right columns is-vcentered">
    <div class="level-item field">
      <input
        id="showAllDataFlag"
        v-model="showAllDataFlag"
        type="checkbox"
        name="showAllData"
        class="switch is-large is-rtl is-rounded"
        @change="updateShowAllDataFlag(showAllDataFlag)"
      >
      <label
        for="showAllDataFlag"
        class="is-relative"
      >Available only</label>
    </div>
  </div>
</template>
<script>
export default {
  props: ['model'],
  data() {
    return {
      showAllDataFlag: false
    };
  },
  methods: {
    updateShowAllDataFlag(notAvailableOnlyData) {
      this.$emit('update-only-available-flag', notAvailableOnlyData);
    }
  }
};
</script>
