import Vue from 'vue';
import appSearch from '../vue-components/apps/search.vue';
import PortalVue from 'portal-vue';
import VeeValidate from 'vee-validate';

Vue.use(PortalVue);
Vue.use(VeeValidate, {
  events: 'change'
});

function search() {
  new Vue({
    el: '#app-search',
    components: {
      appSearch
    },
    data: function () {
      return {
        appId: 'app-search',
        regionalSettings: window.Cms.regionalSettings
      };
    }
  });
}
export default search;
