<template>
  <section
    id="trip-options-section"
    class="tripoptions-section section"
  >
    <div class="">
      <div>
        <tour-filters-view
          :tourfilters="optionFiltersOnLoad"
          :show-loader="showLoader"
          :filter-configuration="filterConfiguration"
          :touroptions="tripOptionsData"
          @send-filters-data="filtersSelectedData"
        />
      </div>
      <tour-options-view
        :touroptions="tourVariants ?? tripOptionsData"
        :count="tripDisplayLimit"
        :tour-loading="tourLoading"
        :regional-settings="regionalSettings"
        @tour-id="updateTourId"
        @itinerary-status="emitItineraryStatus"
      />
    </div>
  </section>
</template>
<script>
import TourFilters from './tourFilters.vue';
import TourOptions from './tourOptions.vue';
import * as searchConstants from '../../search-constants.js';
export default {
  components: {
    'tour-filters-view': TourFilters,
    'tour-options-view': TourOptions
  },
  props: [
    'selectedOption',
    'tripOptions',
    'optionFiltersOnLoad',
    'searchFilterUrl',
    'filterConfiguration',
    'tourLoading',
    'regionalSettings'
  ],
  emits: ['update-tour', 'itinerary-status'],
  data() {
    return {
      tourIdSelected: '',
      tour: {},
      tourfilters: null,
      tourVariants: null,
      tripDisplayLimit: searchConstants.TRIPDISPLAYLIMIT,
      showLoader: false
    };
  },
  computed: {
    activeTabComponent: function () {
      return 'tab-' + this.activeTab;
    },
    tripOptionsData: function () {
      return [...this.tripOptions];
    }
  },

  methods: {
    isTypeOptions(obj, tagsArray) {
      let objLength = Object.keys(obj).length;
      let resultLength = tagsArray.filter(({ type, options }) =>
        options.includes(obj[type])
      ).length;
      return objLength === resultLength;
    },
    getTourOptions(searchData) {
      this.tourVariants = [...this.tripOptions].filter(({ tags }) =>
        this.isTypeOptions(searchData, tags)
      );
    },
    filtersSelectedData(data) {
      this.getTourOptions(data);
    },
    updateTourId(id) {
      this.tourIdSelected = id;
      this.$emit('update-tour', this.tourIdSelected);
    },
    emitItineraryStatus(status) {
      this.$emit('itinerary-status', status);
    }
  }
};
</script>
