import Vue from 'vue';
import HeroCarousel from '../../vue-components/heros/hero-carousel.vue';

function heroCarouselView() {
  new Vue({
    el: '#hero-carousel-section',
    components: {
      'hero-carousel': HeroCarousel
    },
    data() {
      return {
        herocarouseldata: window.Cms.heroModel
      };
    }
  });
}
export default heroCarouselView;
