<template>
  <div
    :class="{ 'is-active': filterVisibility.showTypeFilter }"
    class="dropdown"
  >
    <div class="dropdown-trigger">
      <a
        class="button is-filter"
        href="#"
        aria-haspopup="true"
        aria-controls="dropdown-types"
        :disabled="settings.loading"
        :class="{ 'is-filter-active': settings.filtersText.typesText }"
        @click.prevent="toggleFilter()"
      >
        <span
          v-if="
            !settings.filterTripsContent.travelType.travelTypeMenuText &&
              !settings.filtersText.typesText
          "
        >Travel Type</span>
        <span
          v-if="
            settings.filterTripsContent.vacationBrand.vacationBrandMenuText &&
              !settings.filtersText.typesText
          "
        >{{ settings.filterTripsContent.travelType.travelTypeMenuText }}</span>
        <span v-if="settings.filtersText.typesText">{{ settings.filtersText.typesText }}</span>
        <span
          v-if="!settings.filtersLocked"
          class="icon is-small"
        >
          <i
            class="fas fa-chevron-down"
            aria-hidden="true"
          />
          <i
            class="fas fa-chevron-up"
            aria-hidden="true"
          />
        </span>
      </a>
    </div>
    <div
      id="dropdown-types"
      class="dropdown-menu dropdown-types dropdown-brands"
      role="presentation"
    >
      <div class="dropdown-header">
        <div class="level is-mobile">
          <div class="level-left">
            <h4
              class="dropdown-title"
              v-text="
                settings.filterTripsContent.travelType.travelTypeDisplayText
                  ? settings.filterTripsContent.travelType.travelTypeDisplayText
                  : 'Travel Types'
              "
            />
          </div>
          <div class="level-right">
            <button
              class="dropdown-close"
              @click.prevent="resetInitialState()"
            >
              <span class="is-sr-only">Close Menu</span>
              <span
                class="dropdown-close__icon delete"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
        <keep-alive>
          <search-type-filters
            ref="typeselector"
            :filter-trips-content="settings.filterTripsContent"
            :locked="settings.filtersLocked"
            :types-val="settings.filtersValues.typesVal"
          />
        </keep-alive>
        <a
          :href="settings.filterTripsContent.travelType.learnAboutTravelTypesDisplayLink"
          aria-label="brandsmore"
          class="brandsmore"
        >{{ settings.filterTripsContent.travelType.learnAboutTravelTypesDisplayText }}</a>
      </div>
      <div class="dropdown-footer">
        <div class="buttonholder level is-mobile">
          <button
            class="button is-subtle"
            href="#"
            @click.prevent="$emit('reset-type-filters')"
          >
            Clear
          </button>
          <button
            class="button is-primary"
            @click.prevent="$emit('filter-search')"
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import searchTypeFilters from '../../vue-components/search/search-type-filters.vue';
export default {
  components: {
    searchTypeFilters
  },
  props: {
    settings: {
      type: Object
    },
    filterVisibility: {
      type: Object
    }
  },
  data: function () {
    return {
      initialState: []
    };
  },
  methods: {
    toggleFilter() {
      this.$emit('type-filter-actions', '#dropdown-types');
      if (this.filterVisibility.showTypeFilter) {
        this.initialState = JSON.parse(JSON.stringify(this.settings.filtersValues.typesVal));
      } else {
        this.initialState = [];
      }
    },
    resetInitialState() {
      if (!this.initialState) {
        this.initialState = [];
      }
      this.$emit('get-selected-types', this.initialState);
      this.$emit('set-filter-visibility');
    }
  }
};
</script>
