<template>
  <section class="search-brand-container">
    <div class="check-link-holder search-brand-wrapper is-centered">
      <button
        v-for="(type, typeIndex) in filterTripsContent.travelType.travelTypes"
        :key="'traveltype' + typeIndex"
        class="check-link search-brand-item is-block"
        :data-type-name="typeNameConcat(type)"
        :value="typeNameConcat(type)"
        :class="{ 'is-active': isActive(type), hidden: !type.availableType }"
        :aria-label="type.travelTypeName"
        @click="selectedTypes(type)"
      >
        <div>
          <img
            v-if="type.availableType"
            :class="{ 'is-square': type.isSquare }"
            :src="constants.LOCATION_STRING + type.travelTypeLogoUrl"
            :alt="type.travelTypeName + ' image'"
          >
        </div>
        <span
          v-if="type.availableType"
          class="icon icon-check is-small"
        >
          <i
            v-if="type.availableType"
            class="fas fa-check"
            aria-hidden="true"
          />
        </span>
        <span
          v-if="type.availableType"
          class="has-text-center"
        >{{ type.travelTypeName }}</span>
      </button>
    </div>
  </section>
</template>
<script>
import * as searchConstants from '../../search-constants.js';
import eventBus from '../../vue-functions/event-bus.js';

export default {
  props: ['filterTripsContent', 'locked', 'typesVal'],
  data: function () {
    return {
      buttonActiveClass: 'is-active',
      typesSelected: [],
      constants: searchConstants
    };
  },
  watch: {
    typesVal(val) {
      this.typesSelected = val;
    }
  },
  created() {
    eventBus.$on('types-clear-values', () => {
      this.clearValues();
    });
    this.typesSelected = this.typesVal;
  },
  methods: {
    isActive(type) {
      return this.typesSelected.includes(type.travelTypeName);
    },
    selectedTypes(type) {
      if (this.typesSelected.includes(type.travelTypeName)) {
        let spliceKey = this.typesSelected.indexOf(type.travelTypeName);
        this.typesSelected.splice(spliceKey, 1);
      } else {
        this.typesSelected.push(type.travelTypeName);
      }
      this.$parent.$emit('get-selected-types', this.typesSelected);
    },
    typeNameConcat(type) {
      return type.travelTypeName;
    }
  }
};
</script>
