import Vue from 'vue';
import TourMenuView from '../vue-components/tour-page/tourMenu.vue';
function tourMenuView() {
  new Vue({
    el: '#tour-menu',
    components: {
      'tour-menu': TourMenuView
    },
    data() {
      return {
        datamenu: window.Cms.subNavModel
      };
    }
  });
}
export default tourMenuView;
