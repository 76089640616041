function manageDeepLinkState() {
  let paramsReceived = window.location.search;
  //set up the search term, being text if this exists
  // strip off the ? from the url params
  let deeplinkFilters = paramsReceived.substr(1);
  // // split each param by the &
  let activeUrlFiltersArray = deeplinkFilters.split('&');
  // empty object to add key value pairs for looping through url params
  let constructedFilters = {};
  for (let i = 0; i < activeUrlFiltersArray.length; i++) {
    // create a sub array with the key / value
    let subArr = activeUrlFiltersArray[i].split('=');
    // add to the object as key values
    constructedFilters[subArr[0]] = subArr[1];
  }
  // empty arrays for filter items to be pushed
  let durationObj = {};
  let monthsArray = [];
  let brandsArray = [];
  let typesArray = [];
  let destinationsArray = [];
  let priceObj = {};
  let continentValue = false;
  let brochureCodesArray = [];
  let smallGroupsFlag = false;
  let specialOffersFlag = false;
  // looping through the constructed filters object here, and switching depending on case
  // to either push values to a separate array
  // or call a function to generate the right type for the data concerned
  Object.keys(constructedFilters).forEach(function (key) {
    switch (key) {
      case 'minduration':
        durationObj.minDuration = constructedFilters[key];
        break;
      case 'maxduration':
        durationObj.maxDuration = constructedFilters[key];
        break;
      case 'months':
        processUrlMonths(constructedFilters[key]);
        break;
      case 'brands':
        processUrlBrands(constructedFilters[key]);
        break;
      case 'travelTypes':
        processUrlTypes(constructedFilters[key]);
        break;
      case 'destinations':
        processUrlDestinations(constructedFilters[key]);
        break;
      case 'continents':
        processUrlContinents(constructedFilters[key]);
        break;
      case 'brochurecodes':
        processUrlBrochureCodes(constructedFilters[key]);
        break;
      case 'minprice':
        priceObj.minPrice = constructedFilters[key];
        break;
      case 'maxprice':
        priceObj.maxPrice = constructedFilters[key];
        break;
      case 'tags':
        smallGroupsFlag = true;
        break;
      default:
        break;
    }
  });
  function processUrlMonths(values) {
    let splitMonthValues = values.split(',');
    for (let i = 0; i < splitMonthValues.length; i++) {
      monthsArray.push(splitMonthValues[i]);
    }
  }
  function processUrlBrands(values) {
    let splitBrandValues = values.split(',');
    for (let i = 0; i < splitBrandValues.length; i++) {
      // splitting the brand value from URL by case here to form an array, then joining back to a string
      // with spaces in between the words - so InsightVacations becomes Insight Vacations - to match the BrandName from the api
      let brandItem = splitBrandValues[i]
        .replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2')
        .replace(/([a-z])([A-Z])/g, '$1 $2');
      brandsArray.push(brandItem);
    }
  }
  function processUrlTypes(values) {
    let splitTypeValues = values.split(',');
    for (let i = 0; i < splitTypeValues.length; i++) {
      let typeItem = splitTypeValues[i].match(/[A-Z][a-z]+/g).join(' ');
      typesArray.push(typeItem);
    }
  }
  // // brochurecodes
  function processUrlBrochureCodes(values) {
    if (!values) {
      return;
    }
    let splitBrochureValues = values.split(',');
    for (let i = 0; i < splitBrochureValues.length; i++) {
      let decodedBrochureCode = decodeURIComponent(splitBrochureValues[i]);
      brochureCodesArray.push(decodedBrochureCode);
    }
  }
  // destinations need processing from string to array to pass to getSelectedDestinations
  function processUrlDestinations(values) {
    if (!values) {
      return;
    }
    let splitDestinationValues = values.split(',');
    for (let i = 0; i < splitDestinationValues.length; i++) {
      let decodedDestination = decodeURIComponent(splitDestinationValues[i]);
      destinationsArray.push(decodedDestination);
    }
  }
  function processUrlContinents(value) {
    let decodedContinent = decodeURIComponent(value);
    continentValue = decodedContinent;
  }
  if (Object.keys(durationObj).length > 0) {
    this.getDurationSliderValues(durationObj);
  }
  if (monthsArray.length > 0) {
    this.getSelectedMonths(monthsArray);
  }
  if (brandsArray.length > 0) {
    this.getSelectedBrands(brandsArray);
  }
  if (typesArray.length > 0) {
    this.getSelectedTypes(typesArray);
  }
  if (destinationsArray.length > 0) {
    this.getSelectedDestinations(destinationsArray);
  }
  if (brochureCodesArray.length > 0) {
    this.getSelectedBrochureCodes(brochureCodesArray);
  }
  if (Object.keys(priceObj).length > 0) {
    this.getPriceSliderValues(priceObj);
  }
  if (continentValue) {
    this.setRegionSearch(continentValue);
  }
  this.setSpecialOffersFlag(specialOffersFlag);
}
export default manageDeepLinkState;
